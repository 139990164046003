.ea-content {
  overflow: auto;
  height: auto;
}

.map {
  height: 100%;
  width: 100%;
}

.violation {
  height: 10px;
  width: 10px;
  color: black;
  background-color: black;
}

.popup {
  max-width: 500px;
}

.legend {
  position: absolute;
  bottom: 40px;
  left: 50px;
  background-color: #FFFFFFCC;
  display: flex;
  align-items: center;
  padding: 10px;
  line-height: 0;
}

.legend-item {
  display: inline-flex;
  align-items: center;
  margin: 0 10px;
  padding: 10px;
  cursor: pointer;
}

.legend-item:hover {
  background-color: #EEEEEE;
}

.legend-item-icon {
  display: inline-block;
  border-radius: 20px;
  width: 20px;
  height: 20px;
  margin-right: 8px;
  transition: all 0.15s ease-in-out;
  box-sizing: border-box;
  border: 2px solid transparent;
}

.unfilled {
  box-sizing: border-box;
  border: 2px solid black;
  background-color: transparent !important;
}

.blue {
  background-color: #00AEDB;
}

.yellow {
  background-color: #FFC425;
}

.red {
  background-color: #D11141;
}
