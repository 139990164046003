h4 {
  margin-top: 10px;
  margin-bottom: 2px;
}

select {
  width: 100%;
  height: 50px;
  font-family: inherit;
  font-size: 12pt;
  padding-left: 10px;
}

.properties-content {
  display: flex;
  height: auto;
  overflow: auto; 
}

.photo-grid {
  min-width: 400px;
  width: 400px;
  overflow-y: scroll;
  line-height: 0px;
}

.photo-grid::-webkit-scrollbar {
  width: 5px;
}

.photo-grid::-webkit-scrollbar-track {
  background-color: #FFFFFFAA;
}

.photo-grid::-webkit-scrollbar-thumb {
  background: #AAAAAA;
}

.image-crop {
  width: 50%;
  height: 125px;
  overflow: hidden;
  display: inline-block;
  background-color: white;
}

.image-crop:hover {
  opacity: 0.5;
  cursor: pointer;
}

.selected {
  opacity: 0.7;
}

.photo-box {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  height: 100%;
}

.photo {
  object-fit: contain;
  width: 95%;
  padding: 20px 0px;
}

.photo-full {
  object-fit: cover;
  width: 100%;
}

.properties {
  background-color: #FFFFFFAA;
  min-width: 350px;
  width: 350px;
  box-shadow: #666666 0px 0px 10px;
}

.properties-info {
  padding: 20px;
}

.pending-selection {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
