table {
  text-align: left;
  border-spacing: 5px;
  padding: 5px 15px 5px 70px;
}

td {
  background-color: #F2F2F2;
  border-radius: 10px;
  padding: 7px;
  min-width: 20px;
  width: 20px;
}

td:hover {
  background-color: #D0D0D0;
  cursor: pointer;
}

td.selected {
  color: white;
  background-color: #555;
}

.map {
  position: fixed;
  height: 100%;
  width: 100%;
}

.mapboxgl-canvas {
  left: 0;
}

.control {
  bottom: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: nowrap;
  position: fixed;
  background-color: #FFFFFFDD;
}

.month-slider {
  display: flex;
  overflow: auto;
  height: 90px;
}

.month-slider::-webkit-scrollbar {
  height: 5px;
}

.month-slider::-webkit-scrollbar-track {
  background-color: #FFFFFFCC;
}

.month-slider::-webkit-scrollbar-thumb {
  background: #AAAAAA;
}

.pause-button-container {
  position: fixed;
  cursor: pointer;
  background-color: #FFFFFFAA;
  width: 60px;
  display: grid;
  height: 90px;
}

.pause-button {
  margin: auto;
}

.pause-button-container:hover {
  background-color: #DDDDDDAA;
}
