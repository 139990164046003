.home-content p {
  margin-bottom: 20px;
}

.home-content {
  width: 100vw;
  overflow: auto;
}

.home-text {
  margin: 0 auto;
  padding: 30px 0;
  max-width: 760px;
}
