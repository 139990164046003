.navigation {
  background-color: #FFFFFF;
  box-shadow: #666666 0px 0px 10px;
  display: flex;
  align-items: center;
  flex-flow: row;
  flex-wrap: wrap;
  padding: 10px;
  z-index: 20;
}

.navigation h1 {
  margin-right: 30px;
}

.navigation a {
  margin-right: 10px;
  text-decoration: none;
  color: black;
  padding: 10px;
  border-radius: 5px;
}

.navigation a:hover {
  background-color: #DDDDDD;
}

.active {
  background-color: #EEEEEE;
}
